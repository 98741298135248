import React, { Component } from 'react';
import Link from 'gatsby-link';
import './navigationPage.scss';
import LayoutWrapper from "src/layouts/LayoutWrapper"
import { getColour } from 'utils/get-colour'
import slugify from 'slugify';

class NavigationPage extends Component {

    render() {
        const {
            data,
            location
        } = this.props

        console.log(this.props)

        const thisPageData = data.contentfulNavigationPage

        const contentPages = thisPageData.contentPages

        const thisColour = getColour(location)

        const thisColourStyle = {
            backgroundColor: `${thisColour}`
        }

        const thisLocation = location

        return (
            <LayoutWrapper>
                <div className={'padding-x'}>
                    <ul className={'navigation-page-grid'}>
                        {contentPages && contentPages.map((page, pageIndex) => {
                            const thisSlug = slugify(page.title).toLowerCase()
                            const thisPath = thisLocation.pathname + '/' + thisSlug

                            return (
                                <li key={pageIndex} style={thisColourStyle}>
                                    <Link
                                        to={thisPath}
                                        key={pageIndex}
                                    >
                                        <span>
                                            <img src={page.icon ? page.icon.file.url : null}></img>
                                            <h3>
                                                {page.title}
                                            </h3>
                                        </span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </LayoutWrapper>
        )
    }
}

export default NavigationPage

export const pageQuery = graphql`
    query navigationPageQuery($id: String!) {
        contentfulNavigationPage(
            id: { eq: $id },
        ) {
            title
            englishTitle
            node_locale
            contentPages {
                englishTitle
                title
                icon {
                    file {
                        url
                    }
                }
            }
        }
    }
`